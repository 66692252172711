
import { defineComponent, ref } from "vue"
import SyncInfo from "@/components/SyncInfo.vue"
import store from "@/store"
import ApiService from "@/core/services/ApiService"
import axios from "axios"
import Swal from "sweetalert2/dist/sweetalert2.js"

export default defineComponent({
  name: "status",
  components: {
    SyncInfo
  },
  data() {
    let loading = ref<boolean>(true)
    const syncTimes = {
      "salesforce": 1647967800000,
      "intacct": 1647967800000
    }

    const accountNum = store.getters.activeAccountInfo.id
    const accountDetails = store.getters.activeAccountInfo.details
    const accountLocations = store.getters.activeAccountInfo.relationships.locations
    const lastTrackedCallTime = store.getters.activeAccountInfo.meta.lastTrackedCallTime ?? null
    const connections = [] as Record<string, any>[]
    const syncButtonText = "Sync System Info"
    const syncingStatus = false

    return {
      lastTrackedCallTime,
      loading,
      syncTimes,
      connections,
      accountDetails,
      accountLocations,
      accountNum,
      syncButtonText,
      syncingStatus
    }
  },
  async created() {
    await this.analytics()
    await this.callRail()
    await this.gmb()

    this.loading = false
  },
  methods: {
    timestampConvert(value: number) {
      return new Date(Math.floor(value)).toLocaleString("en-US", {
        weekday: "short",
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
        hour: "numeric",
        minute: "2-digit"
      });
    },
    async callRail() {
      ApiService.setHeader()
      let resp
      try {
        resp = await ApiService.get(`/accounts/${this.accountNum}/callrail-configs`)
      } catch (e) {
        console.error("Problem getting call-rail configs", e)
      }

      if (resp.data.data) {
        resp.data.data.map(item => {
          // @TODO not sure how to get last_call or status
          this.connections.push({
            id: item.id,
            type: item.type,
            last_call: this.lastTrackedCallTime,
            status: true, ...item.attributes
          })
        })
      }
    },
    async gmb() {
      this.accountLocations.map(item => {
        this.connections.push({
          type: "GMB",
          name: item.name || item.city || item.description,
          status: item.gmb_connected || item.status == 1 ? true : false,
          account: item.gmb_email ?? "",
          place_id: item.google_place_id ?? "",
          last_refresh: 1647967800000, // @TODO where do i find this
          yext_id: item.yext_entity_id ?? "",
          yext_connected: item.yext_connected || item.status == 1 ? true : false,
          yext_errors: item.yext_errors ?? [],
          errors: item.gmb_errors ?? []
        })
      })
    },
    async analytics() {
      this.connections.push({
        type: "Analytics",
        status: this.accountDetails.analytics_connected ?? "",
        account: this.accountDetails.analytics_email ?? "",
        ua: this.accountDetails.analytics_ua ?? "",
        ga4: this.accountDetails.property_id ?? "",
        view: this.accountDetails.analytics_view_id ?? "",
        errors: this.accountDetails.analytics_errors ?? [],
        last_refresh: 1647967800000 // @TODO when client sync connected
      })
    },
    async clientSync() {
      let resp

      // Handle Button State
      this.syncButtonText = "Syncing..."
      this.syncingStatus = true

      // Create axios instance without default auth header
      const syncInstance = axios.create()
      delete syncInstance.defaults.headers.common['Authorization']

      const params = {
        key: "kth3go359g85g9nw9p45g98yh69hg956942ng69",
        ClientID: this.accountNum
      }

      // Make request to sync endpoint
      try {
        resp = await syncInstance.get("https://insights.highlevelmarketing.com/clientsyncapi.php", { params })
      } catch (e) {
        console.error("Problem getting client sync", e)
      }

      // Tell User Sync is Complete
      if (resp.data.result == 1) {
        Swal.fire({
          text: "Client sync ran successfully",
          icon: "success",
          confirmButtonText: "Thanks!",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })

        // Empty connections & repopulate with newly synced data
        this.connections = []
        await this.analytics()
        await this.callRail()
        await this.gmb()

      } else {
        Swal.fire({
          text: "Client sync failed",
          icon: "error",
          confirmButtonText: "Okay",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })
      }

      // Reset Button State
      this.syncingStatus = false
      this.syncButtonText = "Sync System Info"
    }
  }
});
