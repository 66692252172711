<template>
  <div class="admin-card">
    <div class="heading" :class="connection.status ? 'connected' : 'error'">
      <div v-if="connection.name">
        {{ connection.name }}
      </div>
      <div v-else>
        {{ connection.type }}
      </div>

      <div v-if="connection.status">
        <i class="bi bi-check-circle"></i>
      </div>
      <div v-else>
        <i class="bi bi-exclamation-circle"></i>
      </div>
    </div>
    <div class="info">
      <div :class="connection.status ? 'connected' : 'error'" class="status connected">
        <strong>{{ connection.type == "GMB" ? "GMB Status" : "Status" }}</strong>
        <div>{{ connection.status ? 'Connected' : 'Error' }}</div>
      </div>
      <div v-if="connection.errors && connection.errors.length > 0" class="error">
        <strong><strong>{{ connection.type == "GMB" ? "GMB Errors" : "Analytics Errors" }}</strong></strong>
        <div class="error-wrapper d-flex flex-column">
          <div v-for="(error, index) in connection.errors" :key="index">{{ error }}</div>
        </div>
      </div>
      <div v-if="connection.account || connection.account == ''">
        <strong>Account</strong>
        <div>{{ connection.account }}</div>
      </div>
      <div v-if="connection.ua || connection.ua == ''">
        <strong>UA Number</strong>
        <div>{{ connection.ua }}</div>
      </div>
      <div v-if="connection.view || connection.view == ''">
        <strong>View ID</strong>
        <div>{{ connection.view }}</div>
      </div>
      <div v-if="connection.place_id || connection.place_id == ''">
        <strong>Place ID</strong>
        <div>{{ connection.place_id }}</div>
      </div>
      <!--      @TODO update with last date of daily stats data-->
      <!--      <div v-if="connection.last_refresh">-->
      <!--        <strong>Refresh Status</strong>-->
      <!--        <div>The most recent {{ connection.type }} data came in {{ filters.formatDate(connection.last_refresh) }} at-->
      <!--          {{ filters.formatTime(connection.last_refresh) }}-->
      <!--        </div>-->
      <!--      </div>-->
      <div v-if="connection.last_call">
        <strong>Last Tracked Call</strong>
        <div>{{
            filters.formatDate(connection.last_call * 1000, {
              month: "long",
              day: "numeric",
              year: "numeric"
            })
          }}
        </div>
      </div>
      <div v-if="connection.yext_id">
        <strong>Yext Entity ID</strong>
        <div>{{ connection.yext_id }}</div>
      </div>
      <div v-if="connection.type == 'GMB'" :class="connection.yext_connected ? 'connected' : 'error'"
           class="status connected">
        <strong>Yext Status</strong>
        <div>{{ connection.yext_connected ? 'Connected' : 'Error' }}</div>
      </div>
      <div v-if="connection.yext_errors && connection.yext_errors.length > 0" class="error">
        <strong>Yext Errors</strong>
        <div class="error-wrapper d-flex flex-column">
          <div v-for="(error, index) in connection.yext_errors" :key="index">{{ error }}</div>
        </div>
      </div>
      <div v-if="connection.ga4 || connection.ga4 == ''">
        <strong>GA4 Property ID</strong>
        <div>{{ connection.ga4 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import filters from "@/assets/ts/_utils/filters"

export default defineComponent({
  name: "SyncInfo",
  props: {
    connection: Object
  },
  setup() {
    return {
      filters
    }
  }
});
</script>
